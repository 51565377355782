import { ContextWrapper } from "config/context";
import AuthFlow from "sections/auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <ContextWrapper>
      <ToastContainer
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme='light'
      />
      <AuthFlow />
    </ContextWrapper>
  );
}

export default App;
