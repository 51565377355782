import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 100vh;
  height: 100%;
`;

export const LayoutFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  text-align: center;
  padding: 20px;
`;

export const LayoutHeader = styled.div`
  display: flex;
  padding: 15px;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

export const LayoutMain = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  padding: 0 20px;
`;

export const LayoutContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  align-items: center;
`;

export const LayoutCover = styled.div`
  display: flex;
  flex: 1.6;
  opacity: 0.8;
  background: url("https://www.boomer.at/fileadmin/_processed_/c/b/csm_kurt-cotoaga-cqbLg3lZEpk-unsplash_a4cacc3938.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    display: none;
  }
`;
