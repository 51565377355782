import { useEffect, useState } from "react";
import { Outlet, Route, Routes } from "react-router";
import Layout from "sections/layout";
import {
  ForgotPassword,
  Login,
  LoginAdmin,
  LoginKey,
  LoginMediaBuyer,
  ResetPassword,
  ResultHandler,
  SelectAccount,
  Signup,
  VerifyEmail,
  VerifyOtp,
} from "components/auth/formTypes";
import { usePlatformContext } from "config/context";
import NotFound from "./NotFound";

function AuthFlow() {
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);
  const context = usePlatformContext();
  const api = context.api;
  const navigate = context.navigate;

  useEffect(() => {
    if (
      (loading && window.location.pathname === "/") ||
      window.location.pathname === "/auth" ||
      window.location.pathname === "/auth/"
    ) {
      api.resetToken().then((data) => {
        if (!data.status) {
          navigate("/auth/select");
          setChecked(false);
          setLoading(false);
        }
      });
    }
    setLoading(false);
  }, [loading, navigate, api]);

  const Redirect = (props) => {
    window.location.replace(props.to);
    return null;
  };

  return (
    <Routes>
      <Route
        index
        element={<Layout loading={loading} setLoading={setLoading} />}
      />
      <Route
        path='redirect'
        element={<Redirect to={process.env.REACT_APP_REDIRECT_URI} />}
      />
      <Route
        path='admin-redirect'
        element={<Redirect to={process.env.REACT_APP_ADMIN_REDIRECT} />}
      />
      <Route
        path='auth'
        element={<Layout loading={loading} setLoading={setLoading} />}
      >
        <Route
          path='login'
          element={
            <Login
              checked={checked}
              setChecked={setChecked}
              handleSubmit={(values) => {
                api.login(values.email, values.password).then((data) => {});
              }}
            />
          }
        />
        <Route
          path='signup'
          element={
            <Signup
              checked={checked}
              setChecked={setChecked}
              handleSubmit={(values) => {
                api
                  .register(values.email, values.password, values.checked)
                  .then((data) => {});
              }}
            />
          }
        />
        <Route path='result' element={<ResultHandler />} />
        <Route path='verify/:token' element={<VerifyEmail api={api} />} />
        <Route
          path='reset-password'
          element={
            <ForgotPassword
              checked={checked}
              setChecked={setChecked}
              handleSubmit={(values) => {
                api.forgotPassword(values.email).then((data) => {});
              }}
            />
          }
        />
        <Route path='reset-password' element={<Outlet />}>
          <Route
            path=':token'
            element={
              <ResetPassword
                checked={checked}
                setChecked={setChecked}
                api={api}
                handleSubmit={(token, values) => {
                  api
                    .resetPassword(token, values.newPassword)
                    .then((data) => {});
                }}
              />
            }
          />
        </Route>
        <Route
          path='select'
          element={<SelectAccount checked={checked} setChecked={setChecked} />}
        />
        {process.env.REACT_APP_ALPHA === "false" && (
          <Route path='mediabuyer' element={<Outlet />}>
            <Route
              path='login'
              element={
                <LoginMediaBuyer
                  checked={checked}
                  setChecked={setChecked}
                  handleSubmit={() => {
                    navigate("/auth/mediabuyer/key");
                  }}
                />
              }
            />
            <Route
              path='key'
              element={
                <LoginKey
                  checked={checked}
                  setChecked={setChecked}
                  handleResend={() => {}}
                />
              }
            />
          </Route>
        )}

        <Route path='admin' element={<Outlet />}>
          <Route
            path='login'
            element={
              <LoginAdmin
                handleSubmit={(values) => {
                  api.requestAdminLogin(values.email).then((data) => {});
                }}
              />
            }
          />
          <Route path=':email/:token' element={<VerifyOtp api={api} />} />
        </Route>
      </Route>
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default AuthFlow;
