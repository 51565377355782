import { toast } from "react-toastify";

export const useCustomToast = () => {
  const showToast = (type, message) => {
    toast.dismiss();
    toast(message, {
      type: type,
      position: "bottom-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "dark",
    });
  };

  return { showToast };
};
