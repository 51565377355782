import axios from "axios";
import { useNavigate } from "react-router";

export const useApi = (props) => {
  const navigate = useNavigate();
  const authInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + "/auth",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const adminInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL + "/admin",
    withCredentials: true,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const toastHandler = (data) => {
    if (!data.status) {
      props.showToast("error", data.message);
    } else {
      props.showToast("success", data.message);
    }
  };

  const apiCall = async (method) => {
    let data;
    try {
      const res = await method();
      data = res.data;
    } catch (err) {
      console.log(err);
      data = err.response.data;
    }
    return data;
  };

  const login = async (email, password) => {
    const data = await apiCall(() =>
      authInstance.post("/login", { email, password })
    );
    if (data.status) {
      navigate("/redirect");
    }
    toastHandler(data);
    return data;
  };

  const register = async (email, password, check) => {
    const data = await apiCall(() =>
      authInstance.post("/register", {
        Email: email,
        Password: password,
        subscription: check,
      })
    );
    if (data.status) {
      navigate("/redirect");
    }
    toastHandler(data);
    return data;
  };

  const resetToken = async () => {
    const data = await apiCall(() => authInstance.post("/token/reset"));
    console.log(data);
    if (data.status) {
      navigate("/redirect");
    }
    return data;
  };

  const forgotPassword = async (email) => {
    const data = await apiCall(() =>
      authInstance.post("/password/reset", {}, { params: { email } })
    );
    toastHandler(data);
    return data;
  };

  const verifyPasswordToken = async (token) => {
    const data = await apiCall(() =>
      authInstance.post("/password/reset/verify", { ResetToken: token })
    );
    return data;
  };

  const resetPassword = async (token, password) => {
    const data = await apiCall(() =>
      authInstance.patch("/password/reset", {
        ResetToken: token,
        newPassword: password,
      })
    );
    if (data.status) {
      navigate(
        "/auth/result?message=Password Reset was Succesfull&success=true"
      );
    }
    toastHandler(data);
    return data;
  };

  const verifyEmail = async (token) => {
    const data = await apiCall(() =>
      authInstance.post("/verify/user", { EmailToken: token })
    );
    return data;
  };

  const requestAdminLogin = async (email) => {
    const data = await apiCall(() =>
      adminInstance.post("/login/init", { email })
    );
    toastHandler(data);
    if (data.status) {
      navigate("/auth/select");
    }
    return data;
  };

  const verifyAdminLogin = async (email, otp) => {
    const data = await apiCall(() =>
      adminInstance.post("/login", { email, otp })
    );
    toastHandler(data);
    return data;
  };

  return {
    login,
    register,
    resetToken,
    forgotPassword,
    verifyPasswordToken,
    resetPassword,
    verifyEmail,
    requestAdminLogin,
    verifyAdminLogin,
  };
};
