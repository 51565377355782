import { Input } from "components/utils/inputs";
import styled from "styled-components";
import { BsInstagram } from "react-icons/bs";
import { HiOutlineShoppingBag } from "react-icons/hi";

import { Constants } from "config/constants";
import { useNavigate } from "react-router";

const colors = Constants.theme.colors;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  padding: 2rem;
  justify-content: center;

  .info-img {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const StyledFormSelect = styled.button`
  display: flex;
  flex-direction: row;
  margin: 15px 0;
  justify-content: center;
  width: 100%;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
  background-color: ${colors.lightGrey};
  align-items: center;
  cursor: pointer;
  user-select: none;
  border: none;

  &:hover {
    background-color: ${colors.mediumGrey};
  }

  &:active {
    opacity: 0.8;
    outline: none;
    transform: scale(0.98);
    transition: transform 0.1s ease-in-out;
  }

  h3 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: 350;
    color: ${colors.charcoalGrey};
  }

  .icon {
    margin-right: 10px;
    font-size: 2rem;
    color: ${colors.primary};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

export const FormSelect = (props) => {
  const navigate = useNavigate();
  return props.instagram ? (
    <StyledFormSelect onClick={() => navigate("/auth/signup")}>
      <BsInstagram className='icon' />
      <h3>Instagram</h3>
    </StyledFormSelect>
  ) : (
    props.mediabuyer && (
      <StyledFormSelect
        disabled
        onClick={() => navigate("/auth/mediabuyer/login")}
      >
        <HiOutlineShoppingBag className='icon' />
        <h3>Customer</h3>
      </StyledFormSelect>
    )
  );
};

export const FormInput = (props) => {
  return <Input {...props} required margin={"15px 0 0 0"} />;
};
