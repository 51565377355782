import {
  LayoutContent,
  LayoutCover,
  LayoutFooter,
  LayoutHeader,
  LayoutMain,
  LayoutWrapper,
} from "components/auth/layout.styles";
import { StyledHeader, StyledParagraph } from "components/utils/text";
import React from "react";
import { Outlet, useNavigate } from "react-router";
import { Loading } from "components/utils/loading";
import { Logo } from "components/utils/logo";

function Layout(props) {
  const navigate = useNavigate();
  return (
    <LayoutWrapper>
      <LayoutMain>
        <LayoutHeader>
          <Logo />
        </LayoutHeader>
        <LayoutContent>
          {props.loading ? <Loading /> : <Outlet />}
        </LayoutContent>
        <LayoutFooter>
          <StyledParagraph fontSize={"0.8rem"} color={"#A1A1A1"}>
            This site is protected by reCAPTCHA and the Google Privacy Policy
            and Terms of Service apply.
          </StyledParagraph>
        </LayoutFooter>
      </LayoutMain>
      <LayoutCover />
    </LayoutWrapper>
  );
}

export default Layout;
