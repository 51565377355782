import { StyledSubmitButton } from "components/utils/buttons";
import { StyledCheckbox } from "components/utils/inputs";
import {
  StyledHeader,
  StyledLink,
  StyledParagraph,
} from "components/utils/text";
import {
  FormInput,
  FormSelect,
  FormWrapper,
} from "components/auth/form.styles";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Loading } from "components/utils/loading";

export const ResultHandler = () => {
  const check = require("../../assets/check.png");
  const error = require("../../assets/error.png");
  const [params] = useSearchParams();

  return (
    <FormWrapper>
      <StyledHeader textAlign='center' margin={"40px 0"}>
        {params.get("message")}
      </StyledHeader>
      <img
        className='info-img'
        src={params.get("success") === "true" ? check : error}
        alt='invalid token'
      />
      <StyledLink
        textAlign={"center"}
        color='#7b7b7b'
        to='/auth'
        margin={"40px 0"}
      >
        Go Back to Login
      </StyledLink>
    </FormWrapper>
  );
};

export const Signup = (props) => {
  const [check, setCheck] = useState(false);
  const [values, setValues] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    checked: true,
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [ready, setReady] = useState({
    email: false,
    password: false,
    confirmPassword: false,
  });

  function handleClick() {
    if (ready.email && ready.password && ready.confirmPassword) {
      props.handleSubmit(values);
    }
  }

  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <StyledHeader margin={"40px 0"}>Become a seller</StyledHeader>
      <FormInput
        onChange={handleChange}
        ready={ready}
        name='email'
        setReady={setReady}
        check={check}
        placeholder='Enter your email'
        type='email'
        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
        error={
          "Please enter a valid email address. For example: `example@bestgrowth.io`"
        }
      />
      <FormInput
        onChange={handleChange}
        ready={ready}
        name='password'
        setReady={setReady}
        check={check}
        placeholder='Enter your password'
        type='password'
        pattern='(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$'
        error={
          "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@, $, !, %, *, ?, &)."
        }
      />
      <FormInput
        onChange={handleChange}
        ready={ready}
        name='confirmPassword'
        setReady={setReady}
        check={check}
        placeholder='Confirm your password'
        type='password'
        pattern={values.password
          .toString()
          .replace(/[-\\/\\^$*+?.()|[\]{}]/g, "\\$&")}
        error={"Passwords must match!"}
      />
      <StyledCheckbox
        margin={"20px 0"}
        setChecked={(e) => setValues({ ...values, checked: e.target.checked })}
        checked={values.checked}
        label={"I agree to recieve offers, news and updates from Bestgrowth"}
      />
      <StyledParagraph fontSize={"0.8rem"} color={"#A1A1A1"}>
        By clicking Create account, you agree to Bestgrowth Terms and Conditions
        and confirm <br /> you have read our Privacy Notice.
      </StyledParagraph>
      <StyledSubmitButton onClick={() => setCheck(true)} type='submit'>
        Create Account
      </StyledSubmitButton>
      <StyledLink textAlign={"center"} color='#7b7b7b' to='/auth/login'>
        Already have an account? Login
      </StyledLink>
      {/* <StyledLink
        textAlign={"center"}
        color='#7b7b7b'
        to='/auth/mediabuyer/login'
      >
        You are a customer? Login here
      </StyledLink> */}
    </FormWrapper>
  );
};

export const Login = (props) => {
  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [check, setCheck] = useState(false);
  const [ready, setReady] = useState({
    email: false,
    password: false,
  });

  function handleClick() {
    if (ready.email && ready.password) {
      props.handleSubmit(values);
    }
  }
  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <StyledHeader margin={"40px 0"}>Login as a Seller</StyledHeader>
      <FormInput
        onChange={handleChange}
        ready={ready}
        name='email'
        defaultValue={values.email}
        setReady={setReady}
        check={check}
        placeholder='Enter your email'
        type='email'
        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
        error={
          "Please enter a valid email address. For example: `example@bestgrowth.io`"
        }
      />
      <FormInput
        onChange={handleChange}
        ready={ready}
        defaultValue={values.password}
        name='password'
        setReady={setReady}
        check={check}
        placeholder='Enter your password'
        type='password'
      />
      <StyledLink
        margin={"15px 0 0 0"}
        textAlign={"left"}
        color='#7b7b7b'
        to='/auth/reset-password'
      >
        Forgot password?
      </StyledLink>
      <StyledSubmitButton
        onClick={() => setCheck(true)}
        type='submit'
        margin={"15px 0"}
      >
        Login
      </StyledSubmitButton>
      <StyledLink textAlign={"center"} color='#7b7b7b' to='/auth/signup'>
        Don't have an account? Sign up
      </StyledLink>
      {/* <StyledLink
        textAlign={"center"}
        color='#7b7b7b'
        to='/auth/mediabuyer/login'
      >
        You are a customer? Login here
      </StyledLink> */}
    </FormWrapper>
  );
};

export const ForgotPassword = (props) => {
  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const [check, setCheck] = useState(false);
  const [ready, setReady] = useState({
    email: false,
  });

  function handleClick(e) {
    if (ready.email) {
      props.handleSubmit(values);
    }
  }
  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick(e);
      }}
    >
      <StyledHeader margin={"40px 0"}>Forgot your Password?</StyledHeader>
      <FormInput
        onChange={handleChange}
        ready={ready}
        name='email'
        setReady={setReady}
        check={check}
        placeholder='Enter your email'
        type='email'
        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
        error={
          "Please enter a valid email address. For example: `example@bestgrowth.io`"
        }
      />
      <StyledSubmitButton
        onClick={() => setCheck(true)}
        type='submit'
        margin={"15px 0"}
      >
        Recieve Email
      </StyledSubmitButton>
      <StyledLink textAlign={"center"} color='#7b7b7b' to='/auth/signup'>
        Don't have an account? Sign up
      </StyledLink>
    </FormWrapper>
  );
};

export const ResetPassword = (props) => {
  const [values, setValues] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const [valid, setValid] = useState(false);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    props.api.verifyPasswordToken(token).then((res) => {
      if (res.status) {
        setValid(true);
      } else {
        navigate("/auth/result?message=Token is invalid&success=false");
      }
    });
  }, [valid, token, navigate, props]);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [check, setCheck] = useState(false);
  const [ready, setReady] = useState({
    newPassword: false,
    confirmPassword: false,
  });

  function handleClick() {
    if (ready.newPassword && ready.confirmPassword) {
      props.handleSubmit(token, values);
    }
  }
  if (valid) {
    return (
      <FormWrapper
        noValidate
        onSubmit={(e) => {
          e.preventDefault();
          handleClick();
        }}
      >
        <StyledHeader margin={"40px 0"}>Reset your Password</StyledHeader>
        <FormInput
          onChange={handleChange}
          ready={ready}
          name='newPassword'
          setReady={setReady}
          check={check}
          placeholder='Enter your new password'
          type='password'
          pattern='(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$'
          error={
            "Password must be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, one digit, and one special character (@, $, !, %, *, ?, &)."
          }
        />
        <FormInput
          onChange={handleChange}
          ready={ready}
          name='confirmPassword'
          setReady={setReady}
          check={check}
          placeholder='Confirm your password'
          type='password'
          pattern={values.newPassword
            .toString()
            .replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&")}
          error={"Passwords must match!"}
        />
        <StyledSubmitButton
          onClick={() => setCheck(true)}
          type='submit'
          margin={"15px 0"}
        >
          Reset Password
        </StyledSubmitButton>
      </FormWrapper>
    );
  } else {
    return <></>;
  }
};

export const LoginMediaBuyer = (props) => {
  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [check, setCheck] = useState(false);
  const [ready, setReady] = useState({
    email: false,
  });

  function handleClick() {
    if (ready.email) {
      props.handleSubmit(values);
    }
  }
  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <StyledHeader margin={"40px 0"}>Welcome Back!</StyledHeader>
      <FormInput
        ready={ready}
        name='email'
        onChange={handleChange}
        setReady={setReady}
        check={check}
        placeholder='Enter your email'
        type='email'
        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
        error={
          "Please enter a valid email address. For example: `example@bestgrowth.io`"
        }
      />
      <StyledSubmitButton
        onClick={() => setCheck(true)}
        type='submit'
        margin={"15px 0"}
      >
        Continue
      </StyledSubmitButton>
      <StyledLink textAlign={"center"} color='#7b7b7b' to='/auth/login'>
        You are a seller? Login here
      </StyledLink>
    </FormWrapper>
  );
};

export const LoginKey = (props) => {
  const [values, setValues] = useState({
    key: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [check, setCheck] = useState(false);
  const [ready, setReady] = useState({
    key: false,
  });

  function handleClick() {
    if (ready.key) {
      props.handleSubmit(values);
    }
  }
  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick();
        props.handleSubmit(values);
      }}
    >
      <StyledHeader margin={"40px 0"}>Login with Email</StyledHeader>
      <FormInput
        onChange={handleChange}
        ready={ready}
        name='key'
        setReady={setReady}
        check={check}
        placeholder='Enter your key from the email'
        type='text'
      />

      <StyledLink
        textAlign={"left"}
        color='#7b7b7b'
        onClick={props.handleResend}
      >
        Resend email
      </StyledLink>

      <StyledSubmitButton
        onClick={() => setCheck(true)}
        type='submit'
        margin={"15px 0"}
      >
        Check Key
      </StyledSubmitButton>
      <StyledLink textAlign={"center"} color='#7b7b7b' to='/auth/login'>
        You are a seller? Login here
      </StyledLink>
      <StyledLink
        textAlign={"center"}
        color='#7b7b7b'
        to='/auth/mediabuyer/login'
      >
        Wrong Email? Go Back
      </StyledLink>
    </FormWrapper>
  );
};

export const SelectAccount = (props) => {
  return (
    <FormWrapper noValidate onSubmit={(e) => e.preventDefault()}>
      <StyledHeader margin={"40px 0"}>Choose one</StyledHeader>
      <FormSelect instagram={true} />
      <FormSelect mediabuyer={true} />
    </FormWrapper>
  );
};

export const VerifyEmail = (props) => {
  const { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    props.api.verifyEmail(token).then((res) => {
      if (res.status) {
        navigate("/auth/result?message=Email is verified&success=true");
      } else {
        navigate("/auth/result?message=Token is invalid&success=false");
      }
    });
  }, [token, navigate, props]);

  //return the loading component
  return <Loading />;
};

export const LoginAdmin = (props) => {
  const [values, setValues] = useState({
    email: "",
  });

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const [check, setCheck] = useState(false);
  const [ready, setReady] = useState({
    email: false,
  });

  function handleClick() {
    if (ready.email) {
      props.handleSubmit(values);
    }
  }
  return (
    <FormWrapper
      noValidate
      onSubmit={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <StyledHeader margin={"40px 0"}>Admin Login</StyledHeader>
      <FormInput
        ready={ready}
        name='email'
        onChange={handleChange}
        setReady={setReady}
        check={check}
        placeholder='Enter your email'
        type='email'
        pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
        error={
          "Please enter a valid email address. For example: `example@bestgrowth.io`"
        }
      />
      <StyledSubmitButton
        onClick={() => setCheck(true)}
        type='submit'
        margin={"15px 0"}
      >
        Send OTP
      </StyledSubmitButton>
      <StyledLink textAlign={"center"} color='#7b7b7b' to='/auth/select'>
        You are at the wrong place? Click here
      </StyledLink>
    </FormWrapper>
  );
};

export const VerifyOtp = (props) => {
  const { email, token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    props.api.verifyAdminLogin(email, token).then((res) => {
      if (res.status) {
        navigate("/admin-redirect");
      } else {
        navigate("/auth/result?message=OTP is invalid&success=false");
      }
    });
  }, [token, navigate, props, email]);

  return <Loading />;
};
