import styled from "styled-components";
import { Link } from "react-router-dom";
import { Constants } from "config/constants";

const colors = Constants.theme.colors;

export const StyledParagraph = styled.p`
  font-size: ${(props) => props.fontSize || "0.8rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  color: ${(props) => props.color || colors.darkGrey};
  margin: ${(props) => props.margin || "5px 0 "};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  max-width: ${(props) => props.maxWidth};
`;

export const StyledLink = styled(Link)`
  width: 100%;
  font-size: ${(props) => props.fontSize || "0.9rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  color: ${(props) => props.color || colors.darkGrey};
  margin: ${(props) => props.margin || "5px 0 "};
  text-align: ${(props) => props.textAlign};
`;

export const StyledHeader = styled.h1`
  font-weight: ${(props) => props.fontWeight || "600"};
  color: ${(props) => props.color || colors.charcoal};
  margin: ${(props) => props.margin || "5px 0 "};
  text-align: ${(props) => props.textAlign};
  line-height: ${(props) => props.lineHeight};
  cursor: ${(props) => props.cursor};
`;
