export const Constants = {
  TOKEN: "token",
  theme: {
    colors: {
      primary: "#1279FC", // Blue (buttons, etc.)
      secondary: "#FFA500", // Orange (buttons, etc.)
      white: "#FFFFFF", // White (backgrounds, etc.)
      lightGrey: "#F5F5F5", // Light Grey (inputs, etc.)
      mediumGrey: "#D3D3D3", // Medium Grey (borders, etc.)
      darkGrey: "#A9A9A9", // Dark Grey (paragraphs, info, formfield text etc.)
      charcoalGrey: "#696969", // Charcoal Grey (important text, etc.)
    },
  },
};
