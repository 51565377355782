import LogoImg from "assets/logo/logo.svg";
import styled from "styled-components";
import { StyledLink } from "./text";

export const LogoContainer = styled(StyledLink)`
  text-decoration: none;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }

  img {
    width: 12rem;
  }

  h1 {
    font-size: 1%.8;
    font-weight: 700;
    color: black;
  }
`;

export const Logo = () => {
  return (
    <LogoContainer to={"/"} reloadDocument={true}>
      <img src={LogoImg} alt='logo' />
    </LogoContainer>
  );
};
