import styled from "styled-components";
import { Constants } from "../../config/constants";

const colors = Constants.theme.colors;

export const StyledSubmitButton = styled.button`
  border: none;
  padding: 8px 12px;
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  background: ${(props) => props.background || colors.primary};
  height: ${(props) => props.height || "50px"};
  border-radius: 1000px;
  color: ${colors.white};
  margin-bottom: 10px;
  margin: ${(props) => props.margin};
  cursor: pointer;
  transition: 0.2s ease-out;

  &:hover {
    background: ${(props) => props.background || colors.primary};
    opacity: 0.9;
    transition: 0.2s ease-out;
  }

  &:active {
    transition: 0.2s ease-out;
    outline: none;
    border: 2px solid #1279fc;
  }

  &:disabled {
    background: ${colors.lightGrey};
    color: ${colors.darkGrey};
    cursor: not-allowed;
  }
`;
